var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "pt-8 pt-md-5 pb-8 pb-md-14" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        !_vm.loaded
          ? _c("Spinner")
          : _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("h1", { staticClass: "mb-0 fw-bold" }, [
                  _vm._v("Affiliate program"),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mb-6 text-muted" }, [
                  _vm._v(
                    "\n                    Read the terms of our affiliate program\n                    "
                  ),
                  _c(
                    "a",
                    { attrs: { href: "https://hikerapi.com/help/affiliate" } },
                    [_vm._v("here")]
                  ),
                  _c("br"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-5" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h4", { staticClass: "mb-0" }, [
                      _c("small", { staticClass: "text-gray-700" }, [
                        _vm._v(_vm._s(_vm.$root.context.currency_symbol)),
                      ]),
                      _vm._v(
                        _vm._s(_vm.$root.context.affiliate_amount) +
                          "\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-gray-700" }, [
                      _vm._v("Affiliate balance"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-xl btn-primary",
                          attrs: { to: { path: "/affiliate/order" } },
                        },
                        [
                          _vm._v(
                            "\n                            Order payout\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table-responsive" },
                  [
                    _c("vuetable", {
                      ref: "vuetable",
                      staticClass: "ui celled table unstackable table-scroll",
                      attrs: {
                        "api-mode": false,
                        data: _vm.tableData,
                        "wrapper-class": "vuetable-wrapper ui basic segment",
                        "table-wrapper": ".vuetable-wrapper",
                        fields: _vm.fields,
                      },
                      on: { "vuetable:pagination-data": _vm.onPaginationData },
                      scopedSlots: _vm._u([
                        {
                          key: "ilink",
                          fn: function (props) {
                            return [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: props.rowData.link,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(props.rowData.linkName))]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary-soft btn-xs",
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.copied = _vm.$root.copyToClipboard(
                                        props.rowData.link
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.copied ? "Copied" : "Copy") +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "actions",
                          fn: function (props) {
                            return [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary mb-1 btn-xs",
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(props.rowData)
                                    },
                                  },
                                },
                                [_vm._v("Edit")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger mb-1 btn-xs",
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(props.rowData)
                                    },
                                  },
                                },
                                [_vm._v("Delete")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mb-1 btn-xs",
                    attrs: { disabled: _vm.disabled },
                    on: { click: _vm.create },
                  },
                  [_vm._v("Create a promocode")]
                ),
              ]),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }