<template>
<section class="pt-8 pt-md-5 pb-8 pb-md-14">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <form class="mb-6" @submit.prevent="save">
                    <p class="mb-6 text-danger" v-for="error in errors.__all__">{{ error }}</p>
                    <div class="form-group">
                        <label class="form-label" for="amount">USDT (TRC20) amount:</label>
                        <div class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon1">
                                {{ $root.context.currency_symbol }}</span>
                            <input type="number" min="500" :max="parseInt($root.context.affiliate_amount)"
                                   class="form-control" id="amount" v-model="form.amount"
                                   placeholder="Enter the amount" aria-describedby="basic-addon1" /><br>
                            <div clear="both" />
                        </div>
                        <small class="form-text text-danger" v-for="error in errors.amount">{{ error }}</small>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="tron_address">Tron address:</label>
                        <input type="text" class="form-control" :class="{'is-invalid': errors.tron_address}" id="tron_address"
                               placeholder="Enter your tron address" v-model="form.tron_address">
                        <small class="form-text text-danger" v-for="error in errors.tron_address">{{ error }}</small>
                    </div>
                    <p>
                        Your request for withdrawal of funds is processed within three working days!
                    </p>
                    <button class="btn w-100 btn-primary" type="submit" :disabled="disabled">
                        Order
                    </button>
                </form>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { NotifyMixin } from '@/mixins'
import { displayErrors } from "utils/string"

export default {
    mixins: [NotifyMixin],
    data() {
        return {
            errors: {},
            form: {},
            disabled: true
        }
    },
    methods: {
        save() {
            this.errors = {}
            this.disabled = true
            this.$root.Payout.save(this.form).then(resp => {
                this.errors = resp.body.errors || {}
                if (resp.body.state) {
                    this.successNotify({ message: "Successful" });
                } else {
                    displayErrors(resp, this);
                }
            }).catch(resp => {
                displayErrors(resp, this);
            }).finally(() => {
                this.disabled = false
            })
        }
    },
    watch: {
        'form.amount'(val, old) {
            this.disabled = val < 500
        }
    }
}
</script>
