import Layout from "layouts/Layout.vue"
import InnerLayout from "layouts/InnerLayout.vue"
import { Home, Login, Logout, Registration, NotFound, Forgot, Reset, Confirm, Unsubscribe, WikiArticle, Invoice, Review } from "components"
import { Features, FAQ, Status, Pricing } from "components"
import { Dashboard, Tokens, AffPromoCodes, AffOrderPayout, Billing, Settings, Plan, ChoosePlan, Pay } from "components/inner"

export default [
    {
        path: "/",
        name: "Outer",
        redirect: "/",
        component: Layout,
        children: [
            {
                path: "/",
                name: "Home",
                component: Home,
                meta: {
                    title: "Home"
                },
            },
            {
                path: "/p/:promocode",
                name: "Promo",
                component: Home,
                meta: {
                    title: "Home"
                },
            },
            {
                path: "/promo/:promocode",
                name: "PromoHome",
                component: Home,
                meta: {
                    title: "Home"
                },
            },
            {
                path: "/features",
                name: "Features",
                component: Features,
                meta: {
                    title: "Features"
                },
            },
            {
                path: "/faq",
                name: "FAQ",
                component: FAQ,
                meta: {
                    title: "FAQ"
                },
            },
            {
                path: "/status",
                name: "Status",
                component: Status,
                meta: {
                    title: "Status"
                },
            },
            {
                path: "/pricing",
                name: "Pricing",
                component: Pricing,
                meta: {
                    title: "Pricing"
                },
            },
            {
                path: "/review",
                name: "Review",
                component: Review,
                meta: {
                    title: "Review"
                },
            },
            {
                path: "/help/*",
                name: "WikiArticle",
                component: WikiArticle,
                meta: {
                    title: "Help"
                },
            },
        ]
    },
    {
        path: "/dashboard",
        name: "Inner",
        redirect: "/dashboard",
        component: InnerLayout,
        children: [
            {
                path: "",
                name: "Dashboard",
                component: Dashboard,
                meta: {
                    title: "Dashboard"
                },
            },
            {
                path: "/tokens",
                name: "Tokens",
                component: Tokens,
                meta: {
                    title: "Tokens"
                },
            },
            {
                path: "/affiliate",
                name: "Affiliate program",
                component: AffPromoCodes,
                meta: {
                    title: "Affiliate program"
                },
            },
            {
                path: "/affiliate/order",
                name: "Order payout",
                component: AffOrderPayout,
                meta: {
                    title: "Order payout"
                },
            },
            {
                path: "/billing",
                name: "Billing",
                component: Billing,
                meta: {
                    title: "Billing"
                },
            },
            {
                path: "/billing/pay",
                name: "Pay",
                component: Pay,
                meta: {
                    title: "Pay"
                },
            },
            {
                path: "/plan",
                name: "Plan",
                component: Plan,
                meta: {
                    title: "Plan"
                },
            },
            {
                path: "/plan/choose/:id",
                name: "Choose plan",
                component: ChoosePlan,
                meta: {
                    title: "Choose plan"
                },
            },
            {
                path: "/settings",
                name: "Settings",
                component: Settings,
                meta: {
                    title: "Settings"
                },
            },
        ]
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: "Login"
        },
    },
    {
        path: "/forgot",
        name: "Forgot",
        component: Forgot,
        meta: {
            title: "Forgot password"
        },
    },
    {
        path: "/reset/:uid/:token",
        name: "Reset",
        component: Reset,
        meta: {
            title: "Reset password"
        },
    },
    {
        path: "/confirm/:uid/:token",
        name: "Confirm",
        component: Confirm,
        meta: {
            title: "Confirm"
        },
    },
    {
        path: "/unsubscribe/:uid/:token/:code",
        name: "Unsubscribe",
        component: Unsubscribe,
        meta: {
            title: "Unsubscribe"
        },
    },
    {
        path: "/logout",
        name: "Logout",
        component: Logout,
        meta: {
            title: "Logout"
        },
    },
    {
        path: "/registration",
        name: "Registration",
        component: Registration,
        meta: {
            title: "Registration"
        },
    },
    {
        path: "/404",
        name: "404",
        component: NotFound,
        meta: {
            title: "404"
        },
    },
    {
        path: "/invoice/:id",
        name: "Invoice",
        component: Invoice,
        meta: {
            title: "Invoice"
        },
    },
    {
        path: "*",
        component: NotFound,
        meta: {
            title: "404"
        }
    }
]
