var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "nav",
      { staticClass: "navbar navbar-expand-lg navbar-light bg-white" },
      [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "router-link",
              { staticClass: "navbar-brand", attrs: { to: { path: "/" } } },
              [_vm._v(_vm._s(_vm.$root.siteName))]
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "collapse navbar-collapse",
                attrs: { id: "navbarCollapse" },
              },
              [
                _vm._m(1),
                _vm._v(" "),
                _c("ul", { staticClass: "navbar-nav ms-auto" }, [
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { path: "/dashboard" } },
                        },
                        [_vm._v("Dashboard")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { path: "/tokens" } },
                        },
                        [_vm._v("Tokens")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { path: "/affiliate" } },
                        },
                        [_vm._v("Affiliate")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { path: "/plan" } },
                        },
                        [_vm._v("Plan")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { path: "/billing" } },
                        },
                        [_vm._v("Billing")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.$root.context.id
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "navbar-btn btn btn-sm btn-primary lift ms-auto",
                        attrs: { to: { path: "/logout" } },
                      },
                      [_vm._v("\n                    Logout\n                ")]
                    )
                  : _c(
                      "router-link",
                      {
                        staticClass:
                          "navbar-btn btn btn-sm btn-primary lift ms-auto",
                        attrs: { to: { path: "/login" } },
                      },
                      [_vm._v("\n                    Login\n                ")]
                    ),
                _vm._v(" "),
                _c("ul", { staticClass: "navbar-nav" }, [
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "avatar avatar-lg",
                        staticStyle: { "margin-left": "10px" },
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { path: "/settings" } } },
                          [
                            _c("img", {
                              staticClass: "avatar-img rounded-circle",
                              attrs: {
                                src: `https://www.gravatar.com/avatar/${_vm.$root.context.gravatar_hash}`,
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "main",
      [
        _vm.$root.context.is_unverified_trial
          ? _c("section", [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-success",
                        attrs: { role: "alert" },
                      },
                      [
                        _vm._v(
                          "\n                            To activate the trial you need to confirm your Telegram ID through our bot by "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `https://t.me/${_vm.$root.context.tg_bot}?start=${_vm.$root.context.token}`,
                            },
                          },
                          [_vm._v("the link")]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("router-view"),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "position-relative" }, [
      _c(
        "div",
        {
          staticClass:
            "shape shape-bottom shape-fluid-x svg-shim text-gray-200",
        },
        [
          _c(
            "svg",
            {
              attrs: {
                viewBox: "0 0 2880 48",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z",
                  fill: "currentColor",
                },
              }),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "footer",
      { staticClass: "py-8 py-md-11 bg-gray-200" },
      [_c("Footer")],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarCollapse",
          "aria-controls": "navbarCollapse",
          "aria-expanded": "false",
          "aria-label": "Menu",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarCollapse",
          "aria-controls": "navbarCollapse",
          "aria-expanded": "false",
          "aria-label": "Menu",
        },
      },
      [_c("i", { staticClass: "fe fe-x" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }