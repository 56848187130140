<template>
<section class="pt-8 pt-md-5 pb-8 pb-md-14">
    <div class="container">
        <Spinner v-if="!loaded" />
        <div class="row" v-else>
            <div class="col-12">
                <h1 class="mb-0 fw-bold">Affiliate program</h1>
                <p class="mb-6 text-muted">
                    Read the terms of our affiliate program
                    <a href="https://hikerapi.com/help/affiliate">here</a><br>
                </p>
                <div class="row mb-5">
                    <div class="col">
                        <h4 class="mb-0">
                            <small class="text-gray-700">{{ $root.context.currency_symbol }}</small>{{ $root.context.affiliate_amount }}
                        </h4>
                        <small class="text-gray-700">Affiliate balance</small>
                    </div>
                    <div class="col-auto">
                        <router-link :to="{path: '/affiliate/order'}" class="btn btn-xl btn-primary">
                            Order payout
                        </router-link>
                    </div>
                </div>
                <div class="table-responsive">
                    <vuetable
                        ref="vuetable"
                        :api-mode="false"
                        :data="tableData"
                        class="ui celled table unstackable table-scroll"
                        wrapper-class="vuetable-wrapper ui basic segment"
                        table-wrapper=".vuetable-wrapper"
                        :fields="fields"
                        @vuetable:pagination-data="onPaginationData"
                        >
                        <template slot="ilink" slot-scope="props">
                            <a :href="props.rowData.link" target="_blank">{{ props.rowData.linkName }}</a>
                            <button @click.prevent="copied = $root.copyToClipboard(props.rowData.link)"
                                    style="cursor:pointer"
                                    class="btn btn-secondary-soft btn-xs">
                                {{ copied ? "Copied" : "Copy" }}
                            </button>
                        </template>
                        <template slot="actions" slot-scope="props">
                            <button class="btn btn-primary mb-1 btn-xs" @click="edit(props.rowData)">Edit</button>
                            <button class="btn btn-danger mb-1 btn-xs" @click="remove(props.rowData)">Delete</button>
                        </template>
                    </vuetable>
                    <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
                </div>
                <button class="btn btn-primary mb-1 btn-xs" @click="create" :disabled="disabled">Create a promocode</button>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { toDateTime } from "utils/date";
import Vuetable from "vuetable-2/src/components/Vuetable.vue";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination.vue";
import Spinner from "../Spinner"


export default {
    components: {
        Vuetable,
        VuetablePagination,
        Spinner,
    },
    data() {
        return {
            copied: false,
            disabled: false,
            loaded: false,
            fields: [
                { name: "name", title: "Name" },
                { name: "code", title: "Code" },
                { name: "__slot:ilink", title: "Invite Link" },
                { name: "invited", title: "Invited" },
                { name: "created_at", title: "Date of creation", callback: toDateTime },
                { name: "__slot:actions", title: "Actions" },
            ],
            tableData: {}
        }
    },
    methods: {
        fetch(data) {
            data = data || {}
            this.$root.PromoCode.get(data).then(resp => {
                resp.body.data.forEach((r) => {
                    const l = window.location;
                    r.link = `${l.protocol}//${l.host}/p/${r.code}`;
                    r.linkName = `${l.hostname}/p/${r.code}`;
                })
                this.tableData = resp.body;
            }).finally(() => {
                this.loaded = true
            })
        },
        create() {
            this.disabled = true
            this.$root.PromoCode.save().then(resp => {
                this.fetch()
            }).finally(() => {
                this.disabled = false
            })
        },
        edit(data) {
            let name = prompt("Change name", data.name)
            if (name) {
                this.$root.PromoCode.update({id: data.id, name}).then(resp => {
                    this.fetch()
                })
            }
        },
        remove(data) {
            if (!confirm("Are you sure?")) return
            this.$root.PromoCode.remove({id: data.id}).then(resp => {
                this.fetch()
            })
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.fetch({ page });
        }
    },
    created() {
        this.fetch()
        setInterval(() => {
            this.copied = false;
        }, 3000)
    }
}
</script>
