<template>
<div>
    <section class="pt-12 pt-md-14 mt-n11 bg-primary">
    </section>
    <section class="position-relative pt-12 pt-md-14 mt-n11">
        <div class="container">
            <div class="row align-items-center text-center text-md-start bg-primary-soft">
                <div class="col-12 col-md-12">
                    <h1 class="display-1 fw-bold">
                        <div>Status</div>
                    </h1>
                    <p class="mb-2">
                        <img src="https://api.checklyhq.com/v1/badges/checks/a5c4c09a-e4b7-49a8-a943-a61b93881066?style=for-the-badge&theme=default" />
                    </p>
                    <p class="mb-8">
                        <img src="https://api.checklyhq.com/v1/badges/checks/a5c4c09a-e4b7-49a8-a943-a61b93881066?style=for-the-badge&theme=dark&responseTime=true" />
                    </p>
                    <h1 class="display-1 fw-bold">
                        <div>Healthcheck</div>
                    </h1>
                    <p>
                        Our healthcheck is available by URL:<br>
                        <code>https://api.hikerapi.com/sys/healthcheck</code>
                    </p>
                    <table class="table">
                        <tr v-for="(val, key) in data">
                            <th scope="row">{{ key }}</th>
                            <td>{{ val }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </section>
</div>
</template>
<script>
export default {
    data() {
        return {
            data: {}
        }
    },
    created() {
        this.$root.HealthCheck.get().then(resp => {
            this.data = resp.body
        })
    }
}
</script>
