var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "pt-12 pt-md-14 mt-n11 bg-primary" }),
    _vm._v(" "),
    _c("section", { staticClass: "position-relative pt-12 pt-md-14 mt-n11" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            staticClass:
              "row align-items-center text-center text-md-start bg-primary-soft",
          },
          [
            _c("div", { staticClass: "col-12 col-md-12" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "table" },
                _vm._l(_vm.data, function (val, key) {
                  return _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(_vm._s(key)),
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(val))]),
                  ])
                }),
                0
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "display-1 fw-bold" }, [
      _c("div", [_vm._v("Status")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("img", {
        attrs: {
          src: "https://api.checklyhq.com/v1/badges/checks/a5c4c09a-e4b7-49a8-a943-a61b93881066?style=for-the-badge&theme=default",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-8" }, [
      _c("img", {
        attrs: {
          src: "https://api.checklyhq.com/v1/badges/checks/a5c4c09a-e4b7-49a8-a943-a61b93881066?style=for-the-badge&theme=dark&responseTime=true",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "display-1 fw-bold" }, [
      _c("div", [_vm._v("Healthcheck")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n                        Our healthcheck is available by URL:"),
      _c("br"),
      _vm._v(" "),
      _c("code", [_vm._v("https://api.hikerapi.com/sys/healthcheck")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }